import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

const DistrictDetails = () => {
    const { districtCode } = useParams();
    const [district, setDistrict] = useState(null);

    useEffect(() => {
        fetch(`https://srep-app.nagpur.pro/api/districts/${districtCode}`)
            .then(response => response.json())
            .then(data => setDistrict(data));
    }, [districtCode]);

    if (!district) return <div>Loading...</div>;

    return (
        <div className="container">
            <h1>{district.district_name}</h1>
            <p>State Code: {district.state_code}</p>
            
            <h2>Blocks</h2>
            <ul style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
                {district.blocks.map(block => (
                    <li key={block.block_code} style={{ listStyleType: 'none' }}>
                        <Link to={`/blocks/${block.block_code}`}>{block.block_name}</Link>
                    </li>
                ))}
            </ul>
            <h2>Other Details</h2>
            <p>Population: {district.population}</p>
            <p>Area: {district.area} sq km</p>
        </div>
    );
};

export default DistrictDetails;