import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DistrictList from './components/DistrictList';
import DistrictDetails from './components/DistrictDetails';
import BlockDetails from './components/BlockDetails';
import VillageDetails from './components/VillageDetails';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<DistrictList />} />
                    <Route path="/districts/:districtCode" element={<DistrictDetails />} />
                    <Route path="/blocks/:blockCode" element={<BlockDetails />} />
                    <Route path="/villages/:villageCode" element={<VillageDetails />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;