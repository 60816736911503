import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

const VillageDetails = () => {
    const { villageCode } = useParams();
    const [village, setVillage] = useState(null);

    useEffect(() => {
        fetch(`https://srep-app.nagpur.pro/api/districts/block/village/${villageCode}`)
            .then(response => response.json())
            .then(data => setVillage(data));
    }, [villageCode]);

    if (!village) return <div>Loading...</div>;

    return (
        <div className="container">
            <h1>{village.village_name}</h1>
            <p>Population: {village.population}</p>
            <p>Area: {village.area} sq km</p>
            <p>Block Name: <Link to={`/blocks/${village.block_code}`}>{village.block_name}</Link></p>
            <p>District Name: {village.district_name}</p>
        </div>
    );
};

export default VillageDetails;