import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const DistrictList = () => {
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        fetch('https://srep-app.nagpur.pro/api/districts/')
            .then(response => response.json())
            .then(data => setDistricts(data));
    }, []);

    return (
        <div className="container">
        <h1>Districts</h1>
        <div className="district-list">
           
            {districts.map(district => (
                <div key={district.district_code} className="district-item">
                    <Link to={`/districts/${district.district_code}`}>
                        {district.district_name}
                    </Link>
                </div>
            ))}
        </div>
        </div>
    );
};

export default DistrictList;