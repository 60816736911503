import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

const BlockDetails = () => {
    const { blockCode } = useParams();
    const [block, setBlock] = useState(null);

    useEffect(() => {
        fetch(`https://srep-app.nagpur.pro/api/districts/block/${blockCode}`)
            .then(response => response.json())
            .then(data => setBlock(data));
    }, [blockCode]);

    if (!block) return <div>Loading...</div>;

    return (
        <div className="container">
            <h1>{block.block_name}</h1>
            
            <p>District: <Link to={`/districts/${block.district_code}`}>{block.district_name}</Link></p>
            <h2>Villages</h2>
            
            <ul style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
                {block.villages.map(village => (
                    <li key={village.village_code} style={{ listStyleType: 'none' }}>
                        <Link to={`/villages/${village.village_code}`}>{village.village_name}</Link>
                    </li>
                ))}
            </ul>
            
        </div>
    );
};

export default BlockDetails;